















import FormValidator from "@/mixins/FormValidator";
import { Component } from "vue-property-decorator";
import { AuthService } from "../services/auth.service";

@Component
export default class extends FormValidator {
  email = "";
  password = "";

  formRef = "formRef";

  isFormValid = false;
  error: Nullable<string> = null;

  async signin() {
    try {
      if (!this.validateForm()) return;
      await AuthService.singin(this.email, this.password);
      this.error = null;
    } catch (err) {
      this.error = "Неправильный email или пароль";
      throw err;
    }
  }
}
